'use client';

import { Portfolio } from '@/modules/portfolio/services/PortfolioService';
import styles from './Projects.module.css';
import Project from './component/Project';
import { calcMasonryHeight } from './calcMasonryHeight';
import { cn } from '@/utils/cn';
import { useEffect, useState } from 'react';

const Projects = (props: { projects: Portfolio['projects']; cols: number }) => {
    const [intersecting, setIntersecting] = useState([] as string[]);

    const { isMobile } = useIsMobile();
    const { style } = calcMasonryHeight({ projects: props.projects, cols: props.cols });

    const shouldPlayMobile =
        typeof window !== 'undefined' && document?.body?.scrollTop > 100 ? intersecting[1] : intersecting[0];

    return (
        <>
            <div className={styles.Projects}>
                <div className={styles.grid} style={style}>
                    {props.projects.map((project, index) => (
                        <div
                            key={project.id}
                            className={cn(
                                styles.item,
                                (props.cols === 2 && styles.col2) || (props.cols === 3 && styles.col3) || '',
                            )}
                        >
                            <Project
                                project={project}
                                play={
                                    isMobile
                                        ? shouldPlayMobile === project.hash_id
                                        : intersecting.includes(project.hash_id)
                                }
                                onIntersect={(isIntersecting) => {
                                    if (isIntersecting) {
                                        setIntersecting((prev) => [...prev, project.hash_id]);
                                    } else {
                                        setIntersecting((prev) => prev.filter((id) => id !== project.hash_id));
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

function useIsMobile() {
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { isMobile };
}

export default Projects;
