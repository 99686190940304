'use client';

import { Portfolio } from '@/modules/portfolio/services/PortfolioService';
import { cn } from '@/utils/cn';
import Link from 'next/link';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './Project.module.css';

const Project = ({
    project,
    play,
    onIntersect,
}: {
    project: Portfolio['projects'][0];
    play: boolean;
    onIntersect?: (isIntersecting: boolean) => void;
}) => {
    const ref = useRef<HTMLAnchorElement>(null);

    const cover = project.assets.find((asset) => asset.asset_type === 'cover' || asset.asset_type === 'image');
    const video = project.assets.find((asset) => asset.asset_type === 'video_clip');

    useLayoutEffect(() => {
        if (!ref.current) return;

        // check if ref is visible in viewport with IntersectionObserver
        const observer = new IntersectionObserver(
            ([entry]) => {
                onIntersect?.(entry.isIntersecting);
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            },
        );

        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return (
        <>
            <Link
                ref={ref}
                href={`https://www.artstation.com/artwork/${project.hash_id}`}
                rel="noreferrer"
                className={styles.Project}
            >
                {cover && <img src={cover?.image_url} alt={project.project} className={cn(styles.image)} />}
                {play && (
                    <video className={styles.video} autoPlay loop muted playsInline>
                        <source src={video?.video_url} type="video/mp4" />
                    </video>
                )}
            </Link>
        </>
    );
};

export default React.memo(Project, (prev, next) => prev.play === next.play);
