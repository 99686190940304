export function calcMasonryHeight(props: { canEdit?: boolean; projects: Array<any>; cols: number }) {
    const { projects: portfolio, canEdit } = props;

    const colsHeight = portfolio?.reduce((all, item, i) => {
        const col = i % props.cols;
        const prev = all[col] || 0;
        const itemHeight = item?.config?.height || 520;
        const gap = 24;

        all[col] = prev + itemHeight + gap;
        return all;
    }, [] as number[]);

    const height = Math.max(...(colsHeight as number[]));
    const style = { height: `${canEdit ? height + 3000 : height}px` };

    return { style };
}
