/* eslint-disable @next/next/no-sync-scripts */
'use client';

import { useRef, useState } from 'react';
import Image from 'next/legacy/image';

import ComingSoon from '@/assets/coming-soon.png';
import VideoThumb from '@/assets/video-thumb.jpg';
import Replay from '@/assets/replay.png';
import YouTube, { YouTubeProps } from 'react-youtube';
import styles from './Showreel.module.css';

const Showreel = () => {
    const [started, setStarted] = useState(false);
    const [ended, setEnded] = useState<boolean | null>(false);
    const playerRef = useRef<any>(null);

    const opts: YouTubeProps['opts'] = {
        playerVars: {
            controls: 1,
            showinfo: 0,
            rel: 0,
            autoplay: 1,
            hd: 1,
            modestbranding: 1,
            playsinline: 1,
        },
    };

    const startPlaying = () => {
        setStarted(true);
        setTimeout(() => {
            playerRef.current.internalPlayer.playVideo();
        });
    };

    const replay = () => {
        setEnded(false);
        startPlaying();
    };

    return (
        <>
            <div className={`${styles.Showreel}`}>
                <div className={styles.backdrop} style={{ top: 0, transform: 'rotate(180deg)' }}></div>
                {ended !== true && (
                    <div className={styles.layout}>
                        <div className={`${styles.video} ${started ? styles.videoStarted : ''}`}>
                            {/* <div className={styles.backdrop}></div> */}
                            <div className={styles.backdrop} style={{ top: 0, transform: 'rotate(180deg)' }}></div>
                            {started ? (
                                <YouTube
                                    ref={playerRef}
                                    videoId={'YpMpkYjURIY'}
                                    opts={opts}
                                    onEnd={() => setEnded(true)}
                                />
                            ) : (
                                <Image
                                    onClick={startPlaying}
                                    className={styles.thumb}
                                    src={VideoThumb}
                                    quality={100}
                                    layout="fill"
                                    alt="background"
                                    objectFit="contain"
                                    objectPosition="center"
                                />
                            )}
                        </div>
                    </div>
                )}
                {ended === true && (
                    <div className={styles.end} onClick={replay}>
                        <Image className={styles.comingSoon} src={ComingSoon} quality={100} alt="coming soon" />
                        <Image className={styles.replay} src={Replay} quality={100} alt="replay" />
                    </div>
                )}
                {/* <div className={styles.backdrop}></div> */}
            </div>
        </>
    );
};

export default Showreel;
